import React from 'react';
import ImageCarousel from './components/image_carousel';
import photos from "./photos"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import PropertyDescription from './components/property_description';
import "./styles.css"

// TODO cdn setup, probably google cloud storage
// TODO api to return list of images to populate carousel hosted on digitalocean or something
// maybe consider using Firebase when I generalize the solution



const App: React.FC = () => {
  const propertyDescription = "Villa Margarita is a luxury vacation rental set in the breathtaking Palomar mountains. With close proximity to many casinos like Harrah's Resort and attractions like San Diego Safari Park Villa Margarita is a perfect home base for your next adventure in San Diego county. The villa can sleep 10 comfortable with over two thousand square feet of ranch style luxury take your next vacation at Villa Margarita."
  const vrboURL = "https://www.vrbo.com/3521679"
  return (
    <Container>
      <Row>
        <Col>
        <h1 className="text-center my-4">Villa Margarita</h1>
          {/* TODO navigation bar */}
        </Col>
      </Row>
      <Row style={{
        // maxHeight: "400px",
      }}>
        <ImageCarousel images={photos}/>
      </Row>
      <Row className="my-4">
        <Col>
          <PropertyDescription text={propertyDescription} vrboLink={vrboURL} />
        </Col>
      </Row>
    </Container>
  );
};

export default App;
