import React from 'react';
import { Card, Button } from 'react-bootstrap';
import vrboLogo from './vrboLogo.svg';
import './styles.css';

interface PropertyDescriptionProps {
  text: string;
  vrboLink: string;
}

const PropertyDescription: React.FC<PropertyDescriptionProps> = ({ text, vrboLink }) => {
  return (
    <Card className="text-center property-description-card">
      <Card.Body>
        <Card.Text>
          {text}
        </Card.Text>
        <div className="book-now-section">
        <Button href={vrboLink} target="_blank" className="vrbo-button">
          <img src={vrboLogo} alt="VRBO logo" className="vrbo-logo" />
          Book now on VRBO
        </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PropertyDescription;
