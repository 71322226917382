import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import "swiper/css";
import { Row, Col } from "react-bootstrap";

interface ImageCarouselProps {
  images: string[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const problematicIndex = 2
  return (
    <Swiper
      modules={[Autoplay]}
      autoplay={{
        delay: 2400,
        disableOnInteraction: true,
      }}
      onSlideNextTransitionStart={(sw: SwiperClass) => {
        if (sw.activeIndex === sw.slides.length - 2) {
          sw.slideTo(0);
        }
      }}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
    >
      {images.map((image, index) => (
        <>
        {/* Render the slide for mobile and desktop */}
        {["mobile", "desktop"].map(view => (
          <SwiperSlide key={`${view}-${index}`} className={view === "mobile" ? "d-block d-md-none" : "d-none d-md-block"} style={{ height: view === "mobile" ? "300px" : "500px" }}>
            <Row className="h-100">
              <Col xs={12} className="h-100">
                <img 
                  src={image} 
                  alt={`Slide ${index}`}
                  className="img-fluid h-100"
                  style={{
                    objectFit: index === problematicIndex ? "cover" : "contain", // Apply "cover" for the problematic image
                    width: view === "desktop" && index === problematicIndex ? "50%" : "100%",
                    maxHeight: "100%"
                  }}
                />
              </Col>
            </Row>
          </SwiperSlide>
        ))}
      </>
      ))}
    </Swiper>
  );
};

export default ImageCarousel;
